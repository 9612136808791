import { createMuiTheme, responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";
import { createContext, useContext, useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

export const light = responsiveFontSizes(createMuiTheme({
  props: {
    MuiTooltip: {
      arrow: true,
      style: {
        pointerEvents: "all",
      },
    },
    MuiButton: {
      disableFocusRipple: true,
    },
    MuiDivider: {
      style: {
        marginTop: 16,
        marginBottom: 16,
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: "TTFirsNeue",
    },
    button: {
      textTransform: "capitalize",
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    type: "light",
    primary: {
      main: "#306bf6",
    },
    secondary: {
      main: "#181818",
    },
    text: {
      primary: "#212936",
      secondary: "#6c727f",
    },
    background: {
      default: "#f4f5f7",
      main: "#fff",
      paper: "#fff",
    },
    common: {
      black: "#0F100B",
      white: "#FCFCFC",
    },
  },
}));

export const dark = responsiveFontSizes(createMuiTheme({
  props: {
    MuiTooltip: {
      arrow: true,
      style: {
        pointerEvents: "all",
      },
    },
    MuiButton: {
      disableFocusRipple: true,
    },
    MuiDivider: {
      style: {
        marginTop: 16,
        marginBottom: 16,
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: "TTFirsNeue",
    },
    button: {
      textTransform: "capitalize",
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    type: "dark",
    primary: {
      main: "#306bf6",
    },
    secondary: {
      main: "#fff",
    },
    background: {
      paper: "#444",
      default: "#222",
    },
    common: {
      black: "#191919",
      white: "#FCFCFC",
    },
  },
}));

export const ThemeSelectorContext = createContext({
  theme: "",
});

export const useThemeSelector = () => useContext(ThemeSelectorContext);

/* eslint-disable-next-line react/display-name */
export const withTheme = (Component) => (props) => {
  const [t, setT] = useState("light");
  const toggleTheme = () => {
    const newTheme = t === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setT(newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setT(savedTheme);
    }
  }, []);
  const theme = t === "light" ? light : dark;

  return (
    <ThemeSelectorContext.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    </ThemeSelectorContext.Provider>
  );
};
