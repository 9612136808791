/* eslint-disable react/no-danger */
import Router, { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import { useAppState, withAppState } from "store/AppState";
import { withSession } from "store/Session";

import TransparentLayout from "layouts/TransparentLayout";
import useLatestVersion from "hooks/useLatestVersion";

import withDialogs from "hocs/withDialogs";
import withToasts from "hocs/withToasts";

import { withTheme } from "theme";
import NProgress from "nprogress";
import { withI18n } from "i18n";

import "nprogress/nprogress.css";
import "global.css";
import Head from "next/head";

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeError", () => NProgress.done());
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());

const App = ({ Component, pageProps }) => {
  const { state, setState } = useAppState();
  const didMountRef = useRef();
  const router = useRouter();
  useLatestVersion();

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  useEffect(() => {
    if (didMountRef.current && !state.hasHistory) {
      setState({ hasHistory: true });
    } else {
      didMountRef.current = true;
    }
  }, [router.pathname]);

  const Layout = Component.Layout || TransparentLayout;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
        />
        <meta property="og:url" content="https://wiki.ratherlabs.com/" />
        <meta property="og:type" content="website" />
        <title>{pageProps.post?.title ? pageProps.post?.title : "Blockchain En Español"}</title>
        <meta property="og:title" content={pageProps.post?.title ? pageProps.post?.title : "Blockchain En Español"} />
        <meta property="og:description" content={pageProps.post?.subtitle ? pageProps.post?.subtitle : "Add byte-sized blockchain knowledge to your morning routine"} />
        <meta property="og:image" content={pageProps.post?.thumbnail ? pageProps.post?.thumbnail : "https://wiki.ratherlabs.com/ratherlabs-logo.png"} />
        <link rel="icon" type="image/png" sizes="32x32" href="/rathercoin-logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
};

const hocs = [
  withDialogs,
  withAppState,
  withToasts,
  withTheme,
  withI18n,
  withSession,
];

const wrappedApp = hocs.reduce((app, wrapper) => wrapper(app), App);

export default wrappedApp;
