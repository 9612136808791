const APIEndpoints = Object.freeze({
  PRESIGN_URL: { method: "POST", url: "/assets/pre-signed-urls" },
  USER: {
    UPDATE: { method: "PATCH", url: "/users/me" },
    GET_BY_ID: (id) => ({ method: "GET", url: `/users/${id}` }),
  },
  SESSION: {
    REFRESH: { method: "POST", url: "/sessions/tokens" },
    GET: (token) => ({ method: "get", url: `/sessions/${token}` }),
    CREATE: {
      WITH_GOOGLE: {
        method: "post",
        url: "/sessions/google",
      },
      WITH_FACEBOOK: {
        method: "post",
        url: "/sessions/facebook",
      },
    },
  },
  POSTS: {
    CREATE: { method: "POST", url: "/posts" },
    ADD_COMMENT: (id) => ({ method: "POST", url: `/posts/${id}/comments` }),
    GET_COMMENTS: (id) => ({ method: "get", url: `/posts/${id}/comments` }),
    EDIT_POST: (id) => ({ method: "PATCH", url: `/posts/${id}` }),
    GET_FEED: { method: "get", url: "/posts/feed" },
    GET_HIGHLIGHTED: { method: "get", url: "/posts/highlighted" },
    GET_BY_TOPICS: (topics) => ({ method: "get", url: `/posts/topics?topics=${topics}` }),
    GET_BY_USER: (id) => ({ method: "GET", url: `/posts/user/${id}` }),
    GET_BY_ID: (id) => ({ method: "get", url: `/posts/${id}` }),
    MOST_REACTED: { method: "get", url: "/comments/most-voted" },
    ADD_VOTE: (id) => ({ method: "post", url: `/posts/${id}/votes` }),
    REMOVE_VOTE: (id) => ({ method: "delete", url: `/posts/${id}/votes` }),
    HIDE: (id) => ({ method: "PATCH", url: `/posts/${id}/hide` }),
  },
  COMMENTS: {
    GET_SUBCOMMENTS: (id) => ({ method: "get", url: `/comments/${id}/comments` }),
    ADD_VOTE: (id) => ({ method: "post", url: `/comments/${id}/votes` }),
    REMOVE_VOTE: (id) => ({ method: "delete", url: `/comments/${id}/votes` }),
  },
  GLOBALS: {
    GET_TOPICS: { method: "get", url: "/globals/topics" },
  },
});

export default APIEndpoints;
